import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import './App.css';

function App() {
  const placeholder = 'pic-placeholder.png';
  const [file, setFile] = useState(placeholder);
  const [checkedCats, setCheckedCats] = useState(['Slutty']);
  const [caption, setCaption] = useState("");
  const catergories = ['Ass', 'Blowjob', 'Cheating', 'Gay', 'Humiliation', 'Jerk Off Encouragement', 'Sex', 'Slutty', 'Step-Taboo', 'Tits'];
  const [font, setFont] = useState('Arial');
  const [size, setSize] = useState(80);
  const [offset, setOffset] = useState(0);
  const [placement, setPlacement] = useState('bottom');
  const [color, setColor] = useState('white');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(true);

  function handleChange(e) {
    try {
      setFile(URL.createObjectURL(e.target.files[0]));
    }
    catch {

    }
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setCheckedCats((prev) => [...prev, name]);
    } else {
      setCheckedCats((prev) => prev.filter(item => item !== name));
    }
  };

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  function handleGenerate() {
    let catergory = checkedCats[getRandomInt(checkedCats.length)];
    catergory = catergory.replace(/\s/g, '');
    let path = './captions/' + catergory + '.txt';
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", path, false);
    rawFile.onreadystatechange = function () {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status === 0) {
          let text = rawFile.responseText;
          let lines = text.split('\n');
          let line = lines[getRandomInt(lines.length)];
          setCaption(line);
        }
      }
    }
    rawFile.send(null);
  }

  const handleDownload = () => {
    const canvas = document.getElementById("myCanvas");
    const dataUrl = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = file + '-captioned.png';
    link.click();
  };

  function createCanvas() {
    var canvas = document.getElementById("myCanvas");
    var context = canvas.getContext("2d");
    const image = new Image();
    image.src = file;

    var widthLimit = screenWidth - 50;
    if (widthLimit > 700) {
      widthLimit = 700;
    }
    var heightLimit = 600;

    image.onload = () => {
      if (image.naturalWidth > widthLimit) {
        image.width = widthLimit;
        image.height = image.naturalHeight * (widthLimit / image.naturalWidth);
      } else if (canvas.height > canvas.width && canvas.height > heightLimit) {
        image.width = image.naturalWidth * (heightLimit / image.naturalHeight);
        image.height = heightLimit;
      }

      canvas.width = image.width;
      canvas.height = image.height;
      window.devicePixelRatio = 2;

      canvas.style.width = image.width + "px";
      canvas.style.height = image.height + "px";

      canvas.width = Math.floor(image.width * window.devicePixelRatio);
      canvas.height = Math.floor(image.height * window.devicePixelRatio);

      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      context.font = size.toString() + 'px ' + font;
      context.fillStyle = color;
      context.textAlign = 'center';
      context.textBaseline = placement;
      context.strokeStyle = 'black';
      context.lineWidth = 4;
      if (offset === 0) {
        setOffset(Number(canvas.height / 10));
      }

      function splitTextToFitCanvas(text, maxWidth, context) {
        const words = text.split(' ');
        let lines = [];
        let currentLine = '';

        words.forEach(word => {
          const testLine = currentLine ? `${currentLine} ${word}` : word;
          const width = context.measureText(testLine).width;

          if (width > maxWidth) {
            lines.push(currentLine);
            currentLine = word;
          } else {
            currentLine = testLine;
          }
        });

        if (currentLine) {
          lines.push(currentLine);
        }

        return lines;
      }

      const captionWidth = context.measureText(caption).width;

      let lines = [];
      if (captionWidth > canvas.width - 20) {
        lines = splitTextToFitCanvas(caption, canvas.width - 20, context);
      } else {
        lines.push(caption);
      }

      let yPosition = placement === 'bottom'
        ? canvas.height - offset
        : offset;

      lines.forEach((line, index) => {
        let lineYPosition = yPosition + (index * size);
        context.strokeText(line, canvas.width / 2, lineYPosition);
        context.fillText(line, canvas.width / 2, lineYPosition);
      });
    };
  }

  const handleCaptionEdit = (event) => {
    setCaption(event.target.value);
  };

  const handleFontChange = (event) => {
    setFont(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleOffsetChange = (event) => {
    setOffset(event.target.value);
  };

  const handlePlacementChange = (event) => {
    setPlacement(event.target.value);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    createCanvas();
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [file, caption, font, size, placement, offset, color, screenWidth]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="App">
          <h2>PORN CAPTION GENERATOR</h2>
          <h6>Generate erotic images using an AI caption generator</h6>
          <h6>Upload an image, select catergories, and hit generate</h6>
          <br></br>
        </div>
      </header>
      <div className="body">
        <canvas id="myCanvas"></canvas>
        <div>
          <input type="file" onChange={handleChange} />
        </div>
        <div>
          {checkedCats.length > 0 &&
            <button type="button" className="generate" onClick={handleGenerate}>Generate</button>
          }
          {file !== 'pic-placeholder.png' &&
            <button type="button" className="download" onClick={handleDownload}>Download</button>
          }
        </div>
        {file !== 'pic-placeholder.png' && <div>
          <div className="input-field-box">
            <table>
              <tbody>
                <tr>
                  <th><label className="field-label">Caption:</label></th>
                  <th><input style={{ marginTop: '10px' }} className="input-caption"
                    type="text"
                    value={caption}
                    onChange={handleCaptionEdit}
                    placeholder="Enter caption here"
                  />
                  </th>
                </tr>
                <tr>
                  <th>
                    <label className="field-label">Font:</label>
                  </th>
                  <th>
                    <select id="dropdown" value={font} onChange={handleFontChange}>
                      <option value="Arial">Arial</option>
                      <option value="Comic Sans MS">Comic Sans</option>
                      <option value="Futura">Futura</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Helvetica">Helvetica</option>
                      <option value="Impact">Impact</option>
                    </select>
                  </th>
                </tr>
                <tr>
                  <th>
                    <label htmlFor="numberInput" className="field-label">Size:</label>
                  </th>
                  <th>
                    <input type="number" id="size-input" className="size-input" value={size} onChange={handleSizeChange} />
                  </th>
                </tr>
                <tr>
                  <th>
                    <label className="field-label">Placement:</label>
                  </th>
                  <th>
                    <select id="dropdown" value={placement} onChange={handlePlacementChange}>
                      <option value="top">Top</option>
                      <option value="bottom">Bottom</option>
                    </select>
                  </th>
                </tr>
                <tr>
                  <th>
                    <label htmlFor="numberInput" className="field-label">Offset:</label>
                  </th>
                  <th>
                    <input type="number" id="size-input" className="size-input" value={offset} onChange={handleOffsetChange} />
                  </th>
                </tr>
                <tr>
                  <th>
                    <label className="field-label">Color:</label>
                  </th>
                  <th>
                    <select id="dropdown" value={color} onChange={handleColorChange}>
                      <option value="black">Black</option>
                      <option value="#00ccff">Blue</option>
                      <option value="#40eb34">Green</option>
                      <option value="#FF69B4">Pink</option>
                      <option value="#9e04d6">Purple</option>
                      <option value="red">Red</option>
                      <option value="white">White</option>
                      <option value="yellow">Yellow</option>
                    </select>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        }
        <div className="checkbox-container input-field-box">
          <table>
            <tbody>
              <tr>
                <th>
                  <label className="catergories-label">Select catergories:</label>
                </th>
              </tr>
            </tbody>
          </table>
          {catergories.map((item) => (
            <div className="checkbox-item" key={item}>
              <input
                type="checkbox"
                name={item}
                id={item}
                onChange={handleCheckboxChange}
                checked={checkedCats.includes(item)}
              />
              <label htmlFor={item}>{item}</label>
            </div>
          ))}
          <br></br>
        </div>
        <Modal isOpen={isModalOpen} onClose={closeModal} className="modal-overlay">
          <div className="modal-content">
            <h2>Adult Content</h2>
            <p>This webpage contains explicit content</p>
            <button className="stay-button" onClick={closeModal}>18+</button>
            <a href="https://en.wikipedia.org/wiki/Special:Random">
              <button className="leave-button">Under 18</button>
            </a>
          </div>
        </Modal>
        <br></br>
        <br></br>
      </div>
    </div >
  );
}

export default App;
